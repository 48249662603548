import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";

const sliderElement = document.querySelector("#my-keen-slider");

if (sliderElement && sliderElement.childElementCount > 1) {
  var interval = 0;
  function autoplay(run) {
    clearInterval(interval);
    interval = setInterval(() => {
      if (run && slider) {
        slider.next();
      }
    }, 2500);
  }
  var loaded = [];

  var slider = new KeenSlider(sliderElement, {
    loop: true,
    duration: 1000,
    created: (s) => {
      const currentSlide = sliderElement.querySelectorAll(".slide-image")[0];
      const nextSlide = sliderElement.querySelectorAll(".slide-image")[1];
      loaded[0] = true;
      loaded[1] = true;
      currentSlide.setAttribute(
        "style",
        `background-image:url(${currentSlide.dataset.image})`
      );
      nextSlide.setAttribute(
        "style",
        `background-image:url(${nextSlide.dataset.image})`
      );
    },
    move: (s) => {
      const slideIdx = s.details().relativeSlide;

      const nextSlide = sliderElement.querySelectorAll(".slide-image")[
        slideIdx + 1
      ];
      if (!loaded[slideIdx + 1] && nextSlide) {
        loaded[slideIdx] = true;
        nextSlide.setAttribute(
          "style",
          `background-image:url(${nextSlide.dataset.image})`
        );
      }
    },
    dragStart: () => {
      autoplay(false);
    },
    dragEnd: () => {
      autoplay(true);
    },
  });

  sliderElement.addEventListener("mouseover", () => {
    autoplay(false);
  });
  sliderElement.addEventListener("mouseout", () => {
    autoplay(true);
  });
  autoplay(true);
}
